/*
These functions make sure WordPress
and JBST play nice together.
*/

jQuery(document).ready(function() {

    // Remove empty P tags created by WP inside of Accordion and Orbit
    //jQuery('.accordion p:empty, .orbit p:empty').remove();


	// Adds Flex Video to YouTube and Vimeo Embeds
	jQuery('iframe[src*="youtube.com"], iframe[src*="vimeo.com"]').addClass('embed-responsive-item').wrap("<div class='embed-responsive embed-responsive-16by9'/>");

  // offcanvas menu
  jQuery('[data-toggle="offcanvas"]').click(function () {
    jQuery('.row-offcanvas').toggleClass('active');
  });

  // Slick-Slider Gosch / Mit Mayo
  jQuery('.gosch-carousel').slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    dots: false,
    nextArrow: '<i class="fas fa-chevron-right"></i>',
    prevArrow: '<i class="fas fa-chevron-left"></i>',
  });

  // Scroll Header Menu
  var position = jQuery('.navbar').position();
  jQuery(window).scroll(function() {
    if (jQuery(window).width() > 992) {
      if (jQuery(window).scrollTop() > position.top) {
							console.log("uber 50");
            jQuery('header .custom-logo').css({
                // 'position': 'fixed',
                // 'z-index': '999',
                // 'width': '5em',
                // 'margin': '0',
                // 'padding': '0',
                // 'top': '13px',
                // 'left': (jQuery(window).width()-1150)*60/100 + 'px'
            });

            jQuery('header .custom-logo-link').css({
                // 'position': 'fixed',
                // 'z-index': '9999',
                // 'top': '13px',
                // 'left': (jQuery(window).width()-1150)*60/100 + 'px'
            });

            jQuery('header .navbar').css({
                'position': 'fixed',
                'z-index': '999',
                'top': '0',
                'right': '1%',
                'left': '1%'
            });

          } else {

            jQuery('header .custom-logo').css({
                // 'position': 'inherit',
                // 'z-index': '999',
                // 'width': '25%',
                // 'margin': '0 auto 3em auto',
                // 'padding-top': '4rem',
                // 'top': 'unset',
                // 'left': 'unset'
            });

            jQuery('header .custom-logo-link').css({
                // 'position': 'unset',
                // 'z-index': '9999',
                // 'top': 'unset',
                // 'left': 'unset'
            });

            jQuery('.navbar').css({
                'position': 'relative',
                'right': 'unset',
                'left': 'unset'
            });

          }
    }
  });

	//Remove Logo in Main Menu when Scrolldown
	jQuery("#scrolllogo").hide();
	jQuery(window).scroll(function() {
	if (jQuery(document).scrollTop() >300) {
		jQuery("#scrolllogo").show();
		jQuery( "#scrolllogo" ).addClass( "fade-in" );
	} else {
		jQuery("#scrolllogo").hide();
	}
});


  // Header-menu
  jQuery( ".navbar-toggler" ).click(function() {
    if (jQuery('.navbar-toggler').attr('aria-expanded') === "true") {
      jQuery('.navbar-toggler .hamburger').removeClass('is-active');
    } else {
      jQuery('.navbar-toggler .hamburger').addClass('is-active');
    }
  });

  // Startseite - von Mayo
  if (jQuery('body').hasClass('page-id-23')) {

    // Scrollto
    jQuery(".mayo-btn").click(function() {
      var windowHeight = jQuery(window).height();
      jQuery('html, body').animate({
          scrollTop: jQuery('.accordion-group').offset().top - (windowHeight / 3)
      }, 500);
    });


    // Produkt-Nav
    jQuery(".anchorLink").click(function(e) {
        e.preventDefault();

        jQuery('#myGroup' + ' .collapse.show').collapse('toggle');

        var target = jQuery(this).attr("href");
        console.log(jQuery(target).offset().top, 'offset');

        jQuery(target).collapse('toggle');

    });

    // Show Nav in Viewport
    (function($) {

    $.fn.visible = function(partial) {

        var $t            = jQuery(this),
            $w            = jQuery(window),
            viewTop       = $w.scrollTop(),
            viewBottom    = viewTop + $w.height(),
            _top          = $t.offset().top,
            _bottom       = _top + $t.height(),
            compareTop    = partial === true ? _bottom : _top,
            compareBottom = partial === true ? _top : _bottom;

      return ((compareBottom <= viewBottom) && (compareTop >= viewTop));

    };

    })(jQuery);

    jQuery(window).scroll(function(event) {

      jQuery("p").each(function(i, el) {
        var el = jQuery(".accordion-group");
        if (el.visible(true)) {
          if (jQuery('.produkt-nav').hasClass('fadeOutRight')) {
            jQuery('.produkt-nav').css('display', 'block');
            jQuery('.produkt-nav').removeClass('fadeOutRight').addClass('fadeInRight');
          }
        } else {
          if (jQuery('.produkt-nav').hasClass('fadeInRight')) {
            jQuery('.produkt-nav').removeClass('fadeInRight').addClass('fadeOutRight');
          }
        }
      });

    });

    // Maps Marker
    jQuery(".leaflet-marker-pane img").attr("src","/wp-content/uploads/leaflet-maps-marker-icons/map-marker-mayo.png");

    }
  // Hide Video Headline
  var vid = document.getElementById("myVideo");
  vid.onpause = function() {
    jQuery(".video-headline").toggle();
  };
  vid.onplay = function() {
    jQuery(".video-headline").toggle();
  };


});
